import { Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import Image from 'next/image';
import { FC, useMemo } from 'react';
import { THeaderProps } from '@/sharedLib';
import { mapButtonTypeToButton } from '../lib/mapButtonTypeToButton';
import { positionChanger } from '../lib/positionChanger';
import setIsDark from '../lib/setIsDark';
import HeaderLayout from './HeaderLayout';
import Menu from './Menu';

const HeaderTemplate_01: FC<THeaderProps> = ({
  logoPosition = true,
  menuPosition = true,
  buttonsPosition = true,
  bg = 'bg.pure',
  logo,
  headerButtonType,
  containerStyles,
  contactData,
  companyName,
}) => {
  const isDarkBurger = setIsDark(bg);

  const HeaderButton = useMemo(
    () => mapButtonTypeToButton[headerButtonType || 'phone'],
    [headerButtonType],
  );

  const logoIsImage = !!logo?.url;

  return (
    <HeaderLayout styles={{ bg: bg ? bg : 'bg.pure', py: '2xs', minH: 16, ...containerStyles }}>
      <Grid
        alignItems='center'
        templateAreas={`'left center right'`}
        width='full'
      >
        {logoPosition && (
          <GridItem
            area='left'
            h={10}
            maxW={logoIsImage ? 32 : { base: 52, md: 80 }}
            minHeight={10}
            minWidth={16}
            position='relative'
            width='full'
          >
            {logo?.url ? (
              <Image
                alt={`${companyName} logo`}
                fill
                loading='eager'
                objectFit='contain'
                objectPosition='left'
                sizes='8rem'
                src={logo?.rawFile || logo?.url || ''}
              />
            ) : (
              <Flex
                alignItems='center'
                h='full'
              >
                <Text
                  fontSize={{ base: 'sm', md: 'md' }}
                  fontWeight='semibold'
                  textTransform='uppercase'
                  verticalAlign='center'
                >
                  {companyName}
                </Text>
              </Flex>
            )}
          </GridItem>
        )}

        {menuPosition && (
          <GridItem
            area={{
              base: 'right',
              md: positionChanger(logoPosition, buttonsPosition),
            }}
            justifySelf={{
              base: 'right',
              md: positionChanger(logoPosition, buttonsPosition),
            }}
          >
            <Menu isDark={isDarkBurger} />
          </GridItem>
        )}
        {buttonsPosition && (
          <GridItem
            area={{
              base: positionChanger(logoPosition, menuPosition),
              md: 'right',
            }}
            justifySelf={{
              base: positionChanger(logoPosition, menuPosition),
              md: 'right',
            }}
          >
            <HeaderButton data={contactData} />
          </GridItem>
        )}
      </Grid>
    </HeaderLayout>
  );
};

export default HeaderTemplate_01;
